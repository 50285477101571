import React, {useEffect, useRef, useState} from 'react'

// Maphooks
import {useMap} from '../maphooks/useMap'
import {useLayers} from '../maphooks/maphooks/layers/useLayers'
import {useLegends} from '../maphooks/maphooks/useLegends'
import {useSelection} from '../maphooks/maphooks/useSelection'
// import { useInfo } from './maphooks/maphooks/useInfo'

// Data
import generateSources from '../layers/sources'
// import layers from './layers/layers'
import generateLayers from '../layers/offsets_demo_layers'
import {all_protos} from '../maphooks/maphooks/layers/protos/all_protos'
import Legend from '../legends/legend'
import {protos as custom_layer_protos} from '../maphooks/maphooks/layers/protos/all_protos'
import {init_viewport, init_layer, active_dataset} from '../data/startup_data'

import {_BarChart as BarChart} from '../panels/stats_panel'
import LayerPicker from '../panels/layer_picker'
import FileSubmission from '../panels/file_submission/FileSubmission'
import {tile_builder_url} from '../config'
import '../App.css';

import SustLogo from '../assets/white-logo-small.png'
import {Navigate} from "react-router-dom";
import {LOGIN} from "../constants/routes";
import {authContext} from "../context/authContext";
import {useContext} from "react";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import xtype from "xtypejs";

function Map({project}) {
    useEffect(() => {
        document.title = "Natural Climate";
    }, []);

    function transformRequest(url, resourceType) {
        // console.log('hello')
        // console.log(url)
        // console.log(resourceType)
        // console.log(url.includes(tile_server_url))
        if (resourceType === 'Tile' && url.includes(tile_builder_url)) {
            // console.log('request transforming...')
            return {
                url: url, headers: {'x-access-token': true}, // credentials: 'include'  // Include cookies for cross-origin requests
            };
        }
    }

    const {
        map, mapContainer, mapLoaded, viewport, style, setStyle, setViewport, flyToViewport
    } = useMap(init_viewport, 'mapbox://styles/mapbox/satellite-v9', // 'mapbox://styles/mapbox/dark-v10',
        'pk.eyJ1Ijoic3VzdGRldmVsb3BlciIsImEiOiJja21tOWJhZjgxaWtmMndtdXdyMng5bGpsIn0.CZlLg_X0_Gp3hqKYRyJ_uw', transformRequest)

    const [allDatasets, setAllDatasets] = useState([])
    const [activeDataset, setActiveDataset] = useState()
    const [availableLayers, setAvailableLayers] = useState({})
    const [sources, setSources] = useState([])

    const user = useContext(authContext);

    useEffect(() => {
        console.log("Current project:", project)
        user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${tile_builder_url}/projects`, {
                        method: 'GET',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': project,
                        }
                    })
                        .then(r => r.json())
                        .then(r => setAllDatasets(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [project])

    useEffect(() => {
        // console.log(sources)
    }, [sources])

    const {
        layerGroup, layerSelectionDependencies, layerVisibilityDependencies, setLayerGroup
    } = useLayers(map, mapLoaded, style, availableLayers, sources, activeDataset)

    const {
        selectedFeatures, selectableLayers
    } = useSelection(map, mapLoaded, mapContainer, activeDataset, layerSelectionDependencies, layerVisibilityDependencies, layerGroup)

    const {
        legends
    } = useLegends(layerGroup, mapLoaded, availableLayers, all_protos)

    useEffect(() => {
        user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    if (!mapLoaded) return
                    // console.log(activeDataset.id)

                    if (activeDataset) {
                        setTimeout(() => {
                            setSources(generateSources(activeDataset))
                        }, 1000)
                        setTimeout(() => {
                            setAvailableLayers(generateLayers(activeDataset))
                        }, 3000)
                        setTimeout(() => {
                            setLayerGroup('wildfire_ai')
                        }, 5000)
                        fetch(`${tile_builder_url}/bounds/${activeDataset['id']}`, {
                            method: 'GET',
                            withCredentials: true,
                            credentials: 'include',
                            headers: {
                                'X-SustGlobal-APIKey': token.claims.api_key,
                            }
                        })
                            .then(r => r.json())
                            .then(b => map.flyToBounds([[b[0], b[1]], [b[2], b[3]]]))
                    }
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [activeDataset, mapLoaded, map])

    useEffect(() => {
        // console.log("Legends")
        // console.log(legends)
    }, [legends])

    useEffect(() => {
        // console.log("Legends")
        // console.log(legends)
    }, [legends])

    return (<div className="App">
        <div ref={mapContainer} className="map-container"></div>
        <BarChart
            data={selectedFeatures}
            selectableLayers={selectableLayers}
            activeDataset={activeDataset}
            layerGroup={layerGroup}/>
        <LayerPicker
            mapLoaded={mapLoaded}
            activeDataset={activeDataset} setActiveDataset={setActiveDataset}
            allDatasets={allDatasets} setAllDatasets={setAllDatasets}
            availableLayers={availableLayers}
            layerGroup={activeDataset ? (activeDataset['type'] === 'Blue Carbon') ? 'cyclone' : layerGroup : layerGroup}
            setLayerGroup={setLayerGroup}
            project={project}/>
        <Legend legend_items={legends}/>
        <img src={SustLogo} className='sust-logo'/>
        {/* <FileSubmission /> */}
        {/* {selectedFeatures.length > 0 && <div className="title">{selectedFeatures[0].properties.FORESTNAME}</div>} */}
        {/* {selectedProject.length > 0 && <div className="title">
        <b>{selectedProject[0].properties['Entity Name']}</b><br></br>
        Acreage: {selectedProject[0].properties['label:acreage']}<br></br>
        Initial Carbon Stock: {JSON.parse(selectedProject[0].properties['label:carbon']).initial_carbon_stock.value} tCO2e per acre<br></br>
        Average Slag Baseline: {JSON.parse(selectedProject[0].properties['label:carbon']).average_slag_baseline.value} tCO2e per acre<br></br>
        </div>} */}
        {/* {selectedFeatures.length > 0 && <div className="title">{selectedFeatures[0].proper ties.NAME}</div>} */}
    </div>);
}

export default Map;
