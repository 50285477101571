import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {FaInfoCircle, FaUserCircle, FaExternalLinkAlt} from "react-icons/fa";
import Popup from 'reactjs-popup';
import SubmissionSplash from '../panels/file_submission/FileSubmission';
import SustLogo from '../assets/white-logo-small.png'
import Logout from '../routes/logout/Logout'
import {useContext, useEffect, useState} from "react";
import {authContext} from "../context/authContext";
import {CE_MAIN, LOGIN, MAP} from "../constants/routes";
import {useLocalLogin} from "../firebase-config";
import {
    ce_url,
    tile_builder_url as server_url,
} from '../config';

// import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const NavigationBar = ({project, setProject, children}) => {
    const user = useContext(authContext);
    const [superUser, setSuperUser] = useState(false);
    const [role, setRole] = useState('viewer');

    useEffect(() => {
        if (user) {
            user.getIdTokenResult()
                .then((token) => {
                    if (!!token.claims.is_superuser) {
                        setSuperUser(token.claims.is_superuser)
                    } else {
                        console.error('superuser status not available for user')
                    }
                })
                .catch((error) => {
                    console.error(`error while retrieving superuser status: ${error}`)
                });
        }
    }, [user])

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${server_url}/roles`, {
                        method: 'GET',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': project,
                        }
                    }).then((res) => {
                        return new Promise((resolve) => res.json()
                            .then((json) => resolve({
                                status: res.status,
                                statusText: res.statusText,
                                ok: res.ok,
                                json,
                            })));
                    }).then((resp) => {
                        if (resp.ok) {
                            setRole(resp['json'])
                        }
                    }).catch((error) => {
                        console.log(error)
                    })
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [user, project])

    let projects = ProjectsToShow(project, setProject)
    let apps = AppsToShow()
    return (<div>
        <div>
            <Navbar expand="sm" className="bg-dark" data-bs-theme="dark"
                    style={{height: '70px', backgroundColor: '#212527'}}>
                <Container style={{background: '#212527', width: '1275px'}}>
                    <Navbar.Brand className='navbar-brand'>
                        <img
                            alt="SUST LOGO"
                            src={SustLogo}
                            width="45%"
                            height="auto"
                        />{' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        {useMenu(user, superUser, apps, projects, project, role)}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
        {children}
    </div>);
}


const AppsToShow = () => {
    const user = useContext(authContext);
    const [apps, setApps] = useState([])

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_url}/api/myapps`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': 'sust-ptg',
                        }
                    })
                        .then(r => r.json())
                        .then(r => setApps(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[APPS_LIST] Error while fetching app list: ${error}`)
            })
    }, [user])

    if (apps.length > 0) {
        return apps.map(
            (app, index) => {
                return (
                    <Dropdown.Item href={app.url} disabled={!app.has_access}
                                   style={{width: '217px'}}>{app.name}</Dropdown.Item>
                )
            }
        )
    }
}

const ProjectsToShow = (project, setProject) => {
    const user = useContext(authContext);
    const [projects, setProjects] = useState([])

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${server_url}/superprojects`, {
                        method: 'GET',
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                        }
                    })
                        .then(r => r.json())
                        .then(r => setProjects(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }, [user])

    if (projects.length > 0) {
        if (project === "") {
            if (projects.includes('nc-demo')) {
                setProject('nc-demo')
            } else {
                setProject(projects[0])
            }
        }
        return projects.map(
            (p, index) => {
                return (
                    <Dropdown.Item onClick={() => setProject(p)} style={{width: '217px'}}>{p}</Dropdown.Item>
                )
            }
        )
    }
    return <></>
}

const useMenu = (user, superUser, apps, projects, project, role) => {
    if (user) {
        const UserDropdownTitle = (<FaUserCircle size={25}/>);
        const infoDropdownTitle = (<FaInfoCircle size={25}/>);
        const externalLink = (<FaExternalLinkAlt size={15}/>);
        return (
            <>
                {
                    role !== "viewer" ?
                        <Nav className="me-auto" style={{
                            width: '100px',
                        }}>
                            <Nav.Item id="create">
                                <Popup trigger={<button className="btn btn-primary"> Create </button>} modal nested>
                                    {close => (
                                        <div>
                                            <SubmissionSplash project={project}/>
                                        </div>
                                    )}
                                </Popup>
                            </Nav.Item>
                        </Nav> : <></>
                }
                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '220px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            color: '#ffffff8c'
                        }}>Apps</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '153px',
                            color: '#ffffff8c'
                        }}/>
                        <Dropdown.Menu>
                            {apps}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-2" style={{
                    border: '1px solid #505050',
                    borderRadius: '4px',
                    width: '220px',
                    height: '38px',
                    lineHeight: '1.5'
                }}>
                    <Dropdown as={ButtonGroup}>
                        <Button style={{
                            fontWeight: '500',
                            backgroundColor: '#212529',
                            border: '0px',
                            borderRadius: '4px',
                            color: '#ffffff8c'
                        }}>Projects</Button>
                        <Dropdown.Toggle split style={{
                            textAlign: 'right',
                            backgroundColor: '#212529',
                            border: '0px',
                            width: '133px',
                            color: '#ffffff8c'
                        }}/>
                        <Dropdown.Menu>
                            {projects}
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {infoDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="https://developers.sustglobal.com/user-guide" target={"_blank"} style={{textDecoration: 'none'}}>
                                {" Climate Explorer User Guide "}
                                {externalLink}
                            </Dropdown.Item>
                            <Dropdown.Item href="https://developers.sustglobal.com/" target={"_blank"}>
                                {' Sust Global Developer Center '}
                                {externalLink}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>

                <Nav className="me-0">
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle
                            style={{textAlign: 'right', backgroundColor: '#212529', color: '#ffffff8c', border: '0px'}}>
                            {UserDropdownTitle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/account/profile">User Profile</Dropdown.Item>
                            <Dropdown.Item onClick={Logout}>Log Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </>
        )
    } else {
        return (<Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                <Nav.Link href={LOGIN}>Login</Nav.Link>
            </Nav>
        </Navbar.Collapse>)
    }
}

export default NavigationBar;